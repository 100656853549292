import React from 'react'
import styled from 'styled-components'

import { Breakpoints, Colors, Type } from '@/styles'
import Width from '../Width/Width'
import LocalizedLink from '../LocalizedLink/LocalizedLink'
import HoverArrow from '@/components/HoverArrow/HoverArrow'

export const NextCaseStudyComponent = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  position: relative;
  height: 135px;
  box-sizing: border-box;
  z-index: 2;
  margin-bottom: 50px;

  @media (min-width: ${Breakpoints.tablet}) {
    height: 195px;
    margin-top: 224px;
    margin-bottom: -224px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    margin-top: 224px;
    height: 282px;
    margin-bottom: -224px;
  }

  &:before {
    content: '';
    position: absolute;
    background-color: ${Colors.gray};
    height: 135px;
    width: 100vw;
    transform: skewY(-9.85deg);
    display: flex;
    justify-content: flex-end;

    @media (min-width: ${Breakpoints.tablet}) {
      height: 195px;
    }

    @media (min-width: ${Breakpoints.desktop}) {
      height: 282px;
    }
  }
`

export const StyledWidth = styled(Width)`
  position: relative;
  z-index: 2;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  margin-bottom: 40px;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 60px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 120px;
  }
`

export const StyledArrow = styled(HoverArrow)`
  margin-left: 18px;
  width: 31px;

  @media (min-width: ${Breakpoints.desktop}) {
    margin-left: 28px;
    width: auto;
    top: 3px;
  }
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  ${Type.header3};
  color: ${Colors.navy};
  display: flex;
  align-items: center;

  &:hover {
    ${StyledArrow} {
      .arrow {
        transform: translate3d(30px, 0, 0);
      }
    }
  }
`
