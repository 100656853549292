import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import useStore from '@/store/'
import * as S from './styles'

const FooterComponent = props => {
  const locale = useStore(state => state.locale)
  const footerDataResult = useStaticQuery(graphql`
    {
      allPrismicFooter {
        nodes {
          lang
          data {
            copyright_text {
              text
            }
            facebook_link {
              url
            }
            linkedin_link {
              url
            }
            message_heading {
              text
            }
            message_text {
              html
            }
            privacy_link_slug
            privacy_link_text {
              text
            }
            terms_link_slug
            terms_link_text {
              text
            }
            trademarks_link_slug
            trademarks_link_text {
              text
            }
            twitter_link {
              url
            }
          }
        }
      }
    }
  `)
  const footerData = footerDataResult.allPrismicFooter.nodes.find(
    node => node.lang === locale
  )?.data

  return (
    <S.FooterComponent>
      <S.Background />
      <S.StyledWidth>
        <S.StyledLogo color="#fff" />
        {footerData?.message_heading?.text && (
          <S.Partner>{footerData.message_heading.text}</S.Partner>
        )}
        <S.CopyAndSocials>
          <S.Copy>
            {footerData?.message_text?.html && (
              <S.Description
                dangerouslySetInnerHTML={{
                  __html: footerData.message_text.html,
                }}
              />
            )}
            <S.MobileSocials
              twitter={footerData?.twitter_link?.url}
              facebook={footerData?.facebook_link?.url}
              linkedIn={footerData?.linkedin_link?.url}
            />
            <S.CopyrightAndLegal>
              <S.StyledCopyright>
                &copy; 2017-{new Date().getFullYear()}{' '}
                {footerData?.copyright_text?.text ?? ''}
              </S.StyledCopyright>
              <div>
                {footerData?.terms_link_slug &&
                  footerData.terms_link_text?.text && (
                    <S.StyledLink to={footerData.terms_link_slug}>
                      {footerData.terms_link_text.text}
                    </S.StyledLink>
                  )}
                {footerData?.privacy_link_slug &&
                  footerData.privacy_link_text?.text && (
                    <S.StyledLink to={footerData.privacy_link_slug}>
                      {footerData.privacy_link_text.text}
                    </S.StyledLink>
                  )}
                {footerData?.trademarks_link_slug &&
                  footerData.trademarks_link_text?.text && (
                    <S.StyledLink to={footerData.trademarks_link_slug}>
                      {footerData.trademarks_link_text.text}
                    </S.StyledLink>
                  )}
              </div>
            </S.CopyrightAndLegal>
          </S.Copy>
          <S.DesktopSocials
            twitter={footerData?.twitter_link?.url}
            facebook={footerData?.facebook_link?.url}
            linkedIn={footerData?.linkedin_link?.url}
          />
        </S.CopyAndSocials>
      </S.StyledWidth>
    </S.FooterComponent>
  )
}

export default FooterComponent
