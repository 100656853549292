import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as S from './styles'
import { Colors } from '@/styles'
import useStore from '@/store/'
import CookieConsent from 'react-cookie-consent'

const Cookies = () => {
  const locale = useStore(state => state.locale)
  const cookieDataResult = useStaticQuery(graphql`
    {
      allPrismicCookie {
        nodes {
          data {
            accept_label {
              text
            }
            decline_label {
              text
            }
            message {
              html
            }
          }
          lang
        }
      }
    }
  `)
  const cookieData = cookieDataResult.allPrismicCookie.nodes.find(
    node => node.lang === locale
  )?.data

  return (
    <CookieConsent
      flipButtons="true"
      buttonText={cookieData?.accept_label?.text}
      enableDeclineButton="true"
      declineButtonText={cookieData?.decline_label?.text}
      cookieName="atCookies"
      style={{
        background: Colors.text,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '.5rem',
      }}
      contentStyle={{
        flex: '1 0 -1px',
      }}
      buttonStyle={{
        color: Colors.text,
        background: Colors.white,
        padding: '.5rem 1.5rem',
        margin: '0 15px',
      }}
      declineButtonStyle={{
        color: Colors.text,
        background: Colors.offWhite,
        padding: '.5rem 1.5rem',
        margin: '0',
      }}
    >
      <S.Text dangerouslySetInnerHTML={{ __html: cookieData?.message?.html }} />
    </CookieConsent>
  )
}

export default Cookies
