import React from 'react'

import * as S from './styles'

const Socials = ({ facebook, twitter, linkedIn, ...rest }) => {
  return (
    <S.SocialsComponent {...rest}>
      {facebook && (
        <S.StyledLink el="a" to={facebook}>
          <S.StyledFacebook />
        </S.StyledLink>
      )}
      {twitter && (
        <S.StyledLink el="a" to={twitter}>
          <S.StyledTwitter />
        </S.StyledLink>
      )}
      {linkedIn && (
        <S.StyledLink el="a" to={linkedIn}>
          <S.StyledLinkedIn />
        </S.StyledLink>
      )}
    </S.SocialsComponent>
  )
}

export default Socials
