import styled from 'styled-components'

import { Width } from '@/components'
import { Sizes } from '@/styles'

export const CaseStudyHeaderComponent = styled.header`
  background: rgba(255, 255, 255, 0.75);
  position: fixed;
  width: 100vw;
  min-height: ${Sizes.navHeight};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: 3;
  backdrop-filter: blur(5px);
`

export const StyledWidth = styled(Width)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 10px;
`
