import React from 'react'

const OpacityStripe = props => {
  return (
    <svg
      {...props}
      width='2560'
      height='1061'
      viewBox='0 0 2560 1061'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.2'
        d='M0 0.799805L2560 414.951V1060.8L0 646.648V0.799805Z'
        fill='#333333'
      />
    </svg>
  )
}

export default OpacityStripe
