import React from 'react'
import styled from 'styled-components'
import { animated } from 'react-spring'
import { Type, Breakpoints, Colors } from '@/styles'
import { Link, LocalizedLink } from '@/components'

export const NavigationComponent = styled.nav`
  position: relative;
  z-index: 10;
  display: flex;
  padding-top: 12px;
  * + * {
    @media (min-width: ${Breakpoints.desktop}) {
      margin-left: 26px;
    }
  }
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  ${Type.navItem}
  color: ${Colors.navy};
  &:hover {
    color: ${Colors.red};
  }
`

export const StyledLocaleLink = styled(Link)`
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-left: 1px solid ${Colors.navy};
  padding-left: 26px;
  color: ${Colors.navy};
  &:hover {
    color: ${Colors.red};
  }
`

export const Desktop = styled.div`
  display: none;
  @media (min-width: ${Breakpoints.desktop}) {
    display: block;
  }
`

export const Mobile = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${Breakpoints.desktop}) {
    display: none;
  }
`

/*

    Hamburger Nav 

*/

export const Container = styled(animated.ul)`
  position: relative;
  will-change: width, height;
  margin: 0;
  padding: 0;
  list-style: none;

  li:last-child {
    margin-top: 13px;
  }
`

export const Item = styled(animated.li)`
  ${Type.navItem};

  width: 100%;
  text-align: right;
  will-change: transform, opacity;

  padding: 4px 0;

  position: relative;
  z-index: 10;
`

export const StyledLocaleMobileLink = styled(Link)`
  position: relative;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding-top: 15px;
  color: ${Colors.navy};
  &:hover {
    color: ${Colors.red};
  }

  :before {
    content: '';
    position: absolute;
    margin: 8px;
    width: 20px;
    height: 1px;
    background-color: ${Colors.navy};
    right: 0;
    top: -8px;
  }
`

export const Toggle = styled(animated.button)`
  text-align: right;
  padding: 0px 8px 8px 8px;
  cursor: pointer;
  border: none;
  background: none;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${Colors.brand};
    border-radius: 4px;
  }

  &:focus:not(.focus-visible) {
    outline: none;
    box-shadow: none;
  }
`
