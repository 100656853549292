import React from 'react'
import { Wordmark } from '@all-turtles/components'
import useStore from '@/store/'
import * as S from './styles'
import { LocalizedLink, Navigation } from '@/components'

const CaseStudyHeader = props => {
  const isNavOpen = useStore(state => state.isNavOpen)
  const actions = useStore(state => state.actions)

  return (
    <S.CaseStudyHeaderComponent>
      <S.StyledWidth>
        <LocalizedLink
          to="/"
          onClick={() => (isNavOpen ? actions.toggleNav() : null)}
        >
          <Wordmark aria-label="All Turtles" />
        </LocalizedLink>
        <Navigation />
      </S.StyledWidth>
    </S.CaseStudyHeaderComponent>
  )
}

export default CaseStudyHeader
